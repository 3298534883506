<template>
  <div class="d-flex-column align-center main">
    <!--  TABLE START  -->
    <div class="table-wrapper d-flex">
      <div class="el-table">
        <table>
          <colgroup>
            <col name="col_1">
            <col name="col_2">
            <col name="col_3">
            <col name="col_4">
          </colgroup>

          <thead class="bg-transparent">
          <tr>
            <th colspan="1" rowspan="1" class="col_1 is-leaf">
              <div class="cell">Invoice</div>
            </th>
            <th colspan="1" rowspan="1" class="col_2 is-leaf">
              <div class="cell">Description</div>
            </th>
            <th colspan="1" rowspan="1" class="col_3 is-leaf">
              <div class="cell">Amount</div>
            </th>
            <th colspan="1" rowspan="1" class="col_4 is-leaf">
              <div class="cell">Date</div>
            </th>
          </tr>
          </thead>

          <tbody>
            <tr
                v-for="(row, index) in tableData"
                :key="index"
                class="el-table__row"
            >
              <td
                  v-for="(col, i) in row"
                  :key="i"
              >
                <div class="cell">{{ col }}</div>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import orderBy from 'lodash/orderBy';
import { mapActions, mapMutations } from 'vuex';
import moment from 'moment';
import msg from '@/utils/constants/msg';
import endpoints from '@/utils/constants/api';
import { formatMoney } from '@/utils/money';

export default {
  name: 'Transactions',
  methods: {
    ...mapActions('views', ['notify']),
    ...mapMutations('root', ['LOADING']),
  },
  mounted() {
    this.$http.get(endpoints.transactions)
      .then((res) => {
        let transformed = res.data.data.map(
          line => ({
            invoice: line._id,
            description: line.description,
            amount: formatMoney(line.amount),
            date: moment(line.createdAt).format('DD/MM/YYYY'),
          }),
        );
        transformed = orderBy(transformed, 'invoice', 'desc');
        this.$set(this, 'tableData', transformed);
        this.LOADING(false);
      })
      .catch(() => {
        this.notify({
          msg: msg.error.apiError,
          route: this.$route.name,
          type: 'warning',
          window,
        });
        this.LOADING(false);
      });
  },
  data() {
    return {
      tableData: [],
    };
  },
};
</script>

<style lang="scss" scoped>

  .el-table {
    overflow-x: auto;
    background-color: transparent;

    .col_1 {
      min-width: 40px;
    }

    .col_2 {
      min-width: 60px;
    }

    .col_3 {
      min-width: 100px;
    }

    .col_4 {
      min-width: 100px;
    }

    .cell {
      font-size: .9em;
      text-align: center;
      white-space: nowrap;
    }

    table {
      width: 100%;
      border-collapse: collapse;

      thead {
        tr, th {
          background-color: transparent;
        }
      }

      tbody {
        tr {
          background-color: white;
        }
      }
    }
  }

  .bg-transparent {
    background-color: transparent !important;
  }
</style>
